import React, { useState, useEffect } from "react";
import FormActionsRow from "../../../components/shared/form-actions-row";
import FormGroup from "../../../components/shared/form-group";
import { SecurityGroupsService } from "../../../services";


const SekurityGroupEditor = props => {
  const groupid = props.location.pathname.substring(
    props.location.pathname.lastIndexOf("/") + 1
  );

  const isNewGroup = groupid === undefined || groupid.length !== 36;

  const [name, setName] = useState("");
 
  const [message, setMessage] = useState("");
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const _securityGroupsService = new SecurityGroupsService();


  useEffect(() => {

    if (!isNewGroup) {
      _securityGroupsService.getItem("/" + groupid).then(result => {

        result = result.result;

        setName(result.name);
  
        setLoaded(true);
      });
    } else {
      setLoaded(true);
    }
  }, []);

  const handleChanges = () => {
    if (!isNewGroup){
      setMessage("Changes Unsaved")
    }
  };

  const submit = e => {
    e.preventDefault();

    if (isFormSubmitting) {
      return;
    }

    if(isNewGroup){
      _securityGroupsService.createItem({
        name: name
      }).then(result => {
        if(result.id !== undefined){
          console.log(result);
        setMessage("Saved");
      }
      });

    }
    else {
      _securityGroupsService.updateItem({
        id: groupid,
        name: name
      }).then(result => {
        setMessage("User was updated");
      })
    }
   

  };

  return (
    <>
      {loaded ? (
        <div className="user-editor justify-content-center container">
          <h1>{isNewGroup ? "Add" : "Edit" } Sekurity Group</h1>
          <form onSubmit={submit}>
            <div className="row">
              <div className="col-12 col-md-4 mb-5">
                <FormGroup label="secGroupEditor.name">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    name="name"
                    value={name || ""}
                    onChange={e => {setName(e.target.value); handleChanges();}}
                  />
                </FormGroup>
                <h5>{message}</h5>
                <FormActionsRow
                  onFormSubmit={submit}
                  isFormSubmitting={isFormSubmitting}
                  goBackUrl="/configuration/sec-groups/"
                ></FormActionsRow>
              </div>
            </div>
          </form>     
        </div>      
      ) : (
        <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
        </div>
      )}
    </>
  );
};

export default SekurityGroupEditor;
