import React from 'react';
import PaginationItem from './components/pagination-item';
import { Link } from 'react-router-dom';
  
const Pagination = ({ pagesCount, itemsCount, selectedPage, onPageChange }) => {
    if (pagesCount <= 1) {
        return (<></>);
    }

    const lag = 2;
    const pageButtons = []; 
    const startPage = Math.max(selectedPage - lag, 1) + (selectedPage - lag == 2 ? 1 : 0);
    const endPage = Math.min(pagesCount, selectedPage + lag + 1);

    console.log("Selected Page");
    console.log(selectedPage);

    console.log("Start Page");
    console.log(startPage);
  
    pageButtons.push(  
        <PaginationItem 
            onPageChange={onPageChange} 
            disabled={selectedPage <= 1}
            page={selectedPage - 1}
            label="&larr;"
            key="Previous"
        />
    ); 
    if (startPage >= 2){
        pageButtons.push(
            <PaginationItem   
                disabled
                page={selectedPage - 1}
                label="..."
                key="PreviousGap"
            />
        ); 
    } 
    var beginn = startPage;
    if(startPage == 2) beginn = 1;

    for (let index = startPage; index <= endPage; index++) {
        if (index === selectedPage) { 
            pageButtons.push(
                <li className="page-item active" aria-current="page" key={index}>
                    <span className="page-link">
                        {index}
                        <span className="sr-only">(current)</span>
                    </span>
                </li>
            );
        }
        else {
            pageButtons.push(
                <PaginationItem 
                    onPageChange={onPageChange}  
                    disable={false}
                    page={index}
                    label={index} 
                    key={index}
                /> 
            );
        } 
    } 

    
    if (pagesCount > endPage){
        pageButtons.push(
            <li className="page-item disabled" key="NextGap"> 
                <span className="page-link">...</span>
            </li>
        ); 
    } 
    pageButtons.push(
        <PaginationItem 
            onPageChange={onPageChange} 
            disabled={selectedPage === pagesCount}
            page={selectedPage + 1}
            label="&rarr;"
            key="Next"
        /> 
    ); 
 
    return ( 
        <div className="pagination-row mt-5">
            <ul className="pagination float-left">
                { pageButtons } 
            </ul>  

            <div className=" float-left float-md-right mt-2">
                Items total: { itemsCount }
            </div>
            <div className="clearfix"></div>
        </div> 
    );
}

export default Pagination;