import React from 'react';
import { Link } from 'react-router-dom';

const PaginationItem = ({ label, disabled, page, onPageChange }) => { 
    return (
        <li className={`page-item ${ disabled ? 'disabled' : ''}`}> 
            {
                !disabled && 
                <Link className="page-link" to={`?page=${page}`} onClick={() => { onPageChange(page) }}>{ label }</Link>             
            }            
            {
                disabled && 
                <span className="page-link">{label}</span>
            }
        </li>
    );
}

export default PaginationItem;