import React, { useState, useEffect } from "react";
import FormActionsRow from "../../../components/shared/form-actions-row";
import FormGroup from "../../../components/shared/form-group";
import queryString from "query-string";
import Select from "react-select";
import Header from "../../../components/shared/header";
import Footer from "../../../components/shared/footer";
import { UsersService, SecurityGroupsService } from "../../../services";
import Translater from '../../../components/shared/translater';
import { authenticationService } from '../../../services/authenticationService';
import translate from '../../../core/translate'; 


const UserEditor = props => {
 
  const userid = props.location.pathname.substring(
    props.location.pathname.lastIndexOf("/") + 1
  );

  const isNewUser = userid === undefined || userid.length !== 36;

  const [username, setUsername] = useState("");
  const [roleNames, setRoleNames] = useState("SEC_VIEWER");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [groupId, setGroupId] = useState();
  const [groupEmpty, setGroupEmpty] = useState(true);
  const [email, setEmail] = useState("");
  const [groupIdList, setGroupIdList] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const _usersService = new UsersService();
  const _securityGroupsService = new SecurityGroupsService();

  
  var group = [];

  var roleOptions = [
    { label: translate("shared.SEC_ADMIN"), value: "SEC_ADMIN" },
    { label: translate("shared.SEC_MANAGER"), value: "SEC_MANAGER" },
    { label: translate("shared.SEC_VIEWER"), value: "SEC_VIEWER" }
  ];

  if(authenticationService.isUserInRoles(["GLOBAL_ADMIN"])){
    roleOptions.push({ label: translate("shared.GLOBAL_ADMIN"), value: "GLOBAL_ADMIN" });
  }
  authenticationService.isUserInRoles(["GLOBAL_ADMIN"]);

  useEffect(() => {
    const value = queryString.parse(props.location.search);

    if (!isNewUser) {
      _usersService.getItem("/" + userid).then(result => {

        result = result.result;

        setUsername(result.userName);
        setPhoneNumber(result.phoneNumber);  
        setGroupId(result.securityGroup);
        setEmail(result.email);

        if(result.sekurityGroups !== null){
          setGroupIdList(result.securityGroups.map(p => p.id));
          setGroupEmpty(false);
        }
      
        setRoleNames(result.role);

        _securityGroupsService.getItems().then(
          result => {
            
            if (result.isSuccess == true)
            {
              result = result.result;

              group = result;

              var groupMap = group.map(val => { 
                var option = {
                  label: val.name,  
                  value: val.id
                }
                return option }); 

              setGroupOptions(groupMap);
                         
              setLoaded(true); 
            }

          }
        );

     
      });
    } else {
      _securityGroupsService.getItems().then(
        result => {
          if (result.isSuccess == true){
            result = result.result;

            group = result;

            var groupMap = group.map(val => { 
              var option = {
                label: val.name,  
                value: val.id
              }
              return option }); 

            setGroupOptions(groupMap);            
            setLoaded(true); 
          }
        }
      );
    }
  }, []);

  const handleChanges = () => {
    if (!isNewUser){
      setMessage("Changes Unsaved")
    }
  };

  const onRolesChanged = async e => {
    setRoleNames(e.value);
    handleChanges();
  };

  const onGroupChanged = async e => {
    if(e!=null){
    setGroupIdList(e.map(v => v.value));
    setGroupEmpty(false);
    }
    else {
      setGroupEmpty(true);
    }
    handleChanges();
  };

  const submit = e => {
    e.preventDefault();

    if (isFormSubmitting) {
      return;
    }

    if(isNewUser){
      _usersService.createItem({
        UserName: username,
        PhoneNumber: phoneNumber,
        Roles: roleNames,
        SecurityGroups: groupIdList,
        email: email  
      }).then(result => {
        if(result.isSuccess == true){
        setMessage("Saved user with password: " + result.result.password);
      }
      });
    }
    else {
      _usersService.updateItem({
        id: userid,
        UserName: username,
        PhoneNumber: phoneNumber,
        Roles: roleNames,
        SecurityGroups: groupEmpty == true ? null : groupIdList,
        email: email
      }).then(result => {
        setMessage("User was updated");
      })
    }
    setIsFormSubmitting(true);

    setIsFormSubmitting(false);

    //onFormSubmit({ id, username, firstName, lastName, roleNames });
  };

  return (
    <>
      {loaded ? (
        <div className="user-editor justify-content-center container">
          <h2>{isNewUser ? "Add" : "Edit" } User</h2>
          <form onSubmit={submit}>
            <div className="row">
              <div className="col-12 col-md-4 mb-5">
                <FormGroup label="userEditor.username">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    name="username"
                    value={username || ""}
                    onChange={e => {setUsername(e.target.value); handleChanges();}}
                  />
                </FormGroup>
                <FormGroup label="userEditor.email">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    name="email"
                    value={email || ""}
                    onChange={e => {setEmail(e.target.value); handleChanges();}}
                  />
                </FormGroup>

                <FormGroup label="userEditor.phonenumber">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    name="firstName"
                    value={phoneNumber || ""}
                    onChange={e => {setPhoneNumber(e.target.value); handleChanges();}}
                  />
                </FormGroup>

                <FormGroup label="userEditor.roles">
                  <Select
                    closeMenuOnSelect={true}
                    options={roleOptions}
                    onChange={onRolesChanged}
                    defaultValue={roleOptions.filter(
                      val => val.value == roleNames
                    )}
                  />
                </FormGroup>
                <FormGroup label="userEditor.group">
                  <Select
                    closeMenuOnSelect={true}
                    options={groupOptions}
                    isMulti={true}
                    onChange={onGroupChanged}
                    defaultValue={groupOptions.filter(
                      val => groupIdList.includes(val.value)
                    )}
                
                  />
                </FormGroup>
                <h5>{message}</h5>
                <FormActionsRow
                  onFormSubmit={submit}
                  isFormSubmitting={isFormSubmitting}
                  goBackUrl="/configuration/users/"
                ></FormActionsRow>
              </div>
            </div>
          </form>
     
        </div>
        
      ) : (
        <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
        </div>
      )}
    </>
  );
};

export default UserEditor;
