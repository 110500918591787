import BaseService from './baseService'; 

export class UsersService extends BaseService {
    constructor(){
        const baseUrl = "/Persons";
        super(baseUrl);
    }
    
    getItem2 = async (id) => {
         
            const url = this.baseUrl + "/" + id;
            //console.log(url);
            const result = await this.getJsonData(url);
            return result;
     
    }

} 