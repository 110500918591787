import { registerLang } from "react-easy-i18n";

registerLang("de", {
  shared: {
    SEC_MANAGER: "Manager",
    SEC_ADMIN: "Lokaler Adminstrator",
    GLOBAL_ADMIN: "Globaler Administrator",
    SEC_VIEWER: "Sichter",
    edit: "Editieren",
    delete: "Löschen",
    sensor: "Sensoren",
    show: "Anzeigen",
    from: 'Von',
    to: 'Bis',
    showstat: 'Diagramm anzeigen',
    measurements: 'Messungen',
    header: {
      mainpage: "Hauptseite",
      configuration: "Konfiguration",
      principal: "Mandant",
      user: "Nutzerverwaltung",
      sekuritygroup: "Sekuritygruppenverwaltung",
      logout: "Abmelden",
      login: "Anmelden",
      sensor: "Sensoren",
      settings: "Einstellungen"
    },
    footer: {
      help: "Hilfe"
    },
    formActionRow: {
      saving: "Wird abgespeichert...",
      save: "Speichern",
      backTo: "Zurück zur Übersicht",
      cancel: "Abbrechen"
    },
    toast: {
      successSaved: "Gespeichert!"
    },
    loader: {
      dataIsLoading: "Bitte warten, daten werden geladen..."
    }
  },
  mainpage: {
    title: "Willkommen bei Graffitierkennungs Webservice",
    gotosensor: "Zu den Sensoren"
  },
  principal: {
    title: "Mandantenverwaltung - Aktiv in: ",
    set: "Diesen Mandanten verwalten",
    add: "Neues Mandant anlegen"
  },
  principalEditor: {
    name: "Name des Mandanten ",
    key: "AES Shared Key"
  },
  secGroupEditor: {
    name: "Name"
  },
  
  sensorsetting: {
    sensorname: "Sensor",
    groupname: "Gruppe",
    action: "Aktion",
    nogroup: "-",
    alias: "Alias"
},
sensorreport:
    {
        notlive: 'Zum Livemodus',
        live: 'Zur statischen Übersicht',
        clearfilter: 'Filter löschen',
        batteryLevel: 'Batteriestand',
        measurements: 'Messungen'
    },
sensorEditor: {
  Group: "Sekuritygruppe",
  title: "Sensorkonfigurationen",
  alias: "Kurzname",
  latitude: "Längengrad",
  longitude: "Breitengrad",
  co2Threshold: "Kohlendioxid Grenzwert",
  dustThreshold: "Feinstaub Grenzwert"
},
  sensorshow: {
    title: "Sensoren",
    alertconfirmed: "Alarm bestätigt",
    linkLocation: "Alarmstelle auf Karte anzeigen",
    confirmed: "bestätigt",
    unconfirmed: "unbestätigt",
    dust: "Feinstaub",
    co2: "Co2",
    batterylevel: "Batteriestand",
    nogpsdata: "Keine GPS-Daten vorhanden",
    confirm: "Als bestätigt markieren",
    alert: "Alarm",
    linklocationping: "Pingstelle anzeigen"
},
  user: {
    title: "Nutzerverwaltung",
    username: "Name",
    group: "Gruppe",
    action: "Action",
    changepassword: "Passwort ändern",
    add: "Neuen Nutzer Anlegen",
    role: "Role"
  },
  userEditor: {
    phonenumber: "Telefonnummer",
    group: "Sekuritygruppe"
  },
  group: {
    title: "Sekuritygruppenverwaltung",
    add: "Neue Sekuritygruppe anlegen",
    action: "Aktionen",
    name: "Name",
    changepassword: "Passwort ändern",
    add: "Neue Sekuritygruppe anlegen"
  },
  loginPage: {
    loginText: "Loggen sie sich bitte ein",
    username: "Nutzername",
    password: "Passwort",
    login: "Login",
    loggining: "einloggen...",
    messages: {
      invalidUsernameOrPassword: "Invalid username or password"
    }
  },
  usersPage: {
    title: "Nutzverwaltung",
    table: {
      name: "Name",
      username: "Nutzername",
      roles: "Role",
      email: "Email",
      company: "Firma",
      edit: "Editieren",
      resetPassword: "Passwort zurücksetzen"
    },
    add: "Hinzufügen"
  },
  userEditor: {
    email: "Email",
    username: "Nutzername",
    firstName: "Vorname",
    lastName: "Nachname",
    password: "Passwort",
    password1: "Passwort",
    password2: "Wiederhole Passwort",
    phonenumber: "Telefonnummer",
    group: "Sekuritygruppe",
    company: "Company",
    roles: "Rolen",
    validationErrors: {
      emailExists: "Email exists",
      usernameExists: "Username exists",
      companyIdIsRequired: "Company is required",
      roleNamesAreRequired: "Role is required",
      roleIsNotAvailiable: "Role is not available"
    }
  },
  userAddPage: {
    title: "Neuen Nutzer anlegen"
  },
  userEditPage: {
    title: "Nutzer editieren"
  }
});
