import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import Translater from "../translater/";
import { Link, Redirect } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { authenticationService } from "../../../services/authenticationService";
import frogLogo from "./frog-logo.png";

import { history } from "../../../core/history";
import AccessBoundary from "../access-boundary";

const Header = ({ lang, onLanguageChange }) => {
  const currentUser = authenticationService.currentUserValue;
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const getClassNamesForMenu = menuName => {
    //return '' + (activeMenu === menuName && 'active');
    return "";
  };

  const onLogout = e => {
    e.preventDefault();
    authenticationService.logout();
    setRedirectToLogin(true);
    history.push("/login/");
  };

  let logo = (
    <span>
      <img src={frogLogo} alt="logo" /> Graffiti
    </span>
  );

  return (
    <header className="header" id="header">
      <div className="container px-0 px-md-3">
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          className="custom-navbar"
        >
          {redirectToLogin ? <Redirect to="/Login" /> : <></>}
          <Navbar.Brand href="/">{logo}</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <AccessBoundary
                content={() => {
                  return (
                    <>
                      <LinkContainer to="/map">
                        <Nav.Link>
                          <Translater text="shared.header.mainpage" />
                        </Nav.Link>
                      </LinkContainer>
                      <AccessBoundary
                        //roles={["GLOBAL_ADMIN", "SEC_ADMIN"]}
                        //new code
                        roles={["GLOBAL_ADMIN"]}
                        //new code
                        content={() => {
                          return (
                            <>
                              <NavDropdown
                                title={
                                  <Translater text="shared.header.configuration" />
                                }
                                id="collasible-nav-dropdown-configuration"
                              >
                                <AccessBoundary
                                  roles={["GLOBAL_ADMIN"]}
                                  content={() => {
                                    return (
                                      <>
                                        <LinkContainer to="/configuration/principals">
                                          <NavDropdown.Item>
                                            <Translater text="shared.header.principal" />
                                          </NavDropdown.Item>
                                        </LinkContainer>
                                        <NavDropdown.Divider />
                                      </>
                                    );
                                  }}
                                />
                                <LinkContainer to="/configuration/users">
                                  <NavDropdown.Item>
                                    <Translater text="shared.header.user" />
                                  </NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/configuration/sec-groups/">
                                  <NavDropdown.Item>
                                    <Translater text="shared.header.sekuritygroup" />
                                  </NavDropdown.Item>
                                </LinkContainer>
                              </NavDropdown>
                            </>
                          );
                        }}
                      />
                      <NavDropdown
                        title={<Translater text="shared.header.sensor" />}
                        id="collasible-nav-dropdown-configuration">
                        <LinkContainer to="/sensor/settings">
                          <NavDropdown.Item>
                            <Translater text="shared.header.settings" />
                          </NavDropdown.Item>
                        </LinkContainer>
                      </NavDropdown>
                    </>
                  );
                }}
              />
            </Nav>
            <Nav>
              <NavDropdown title={lang} id="collasible-nav-dropdown-lang">
                <NavDropdown.Item onClick={() => onLanguageChange("EN")}>
                  Englisch
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => onLanguageChange("DE")}>
                  Deutsch
                </NavDropdown.Item>
              </NavDropdown>
              <AccessBoundary
                content={() => {
                  return (
                    <>
                      <LinkContainer to="/account/">
                        <Nav.Link className={getClassNamesForMenu("account")}>
                          <i className="fas fa-user-circle mr-1"></i>
                          {currentUser.username}
                        </Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/login">
                        <Nav.Link onClick={onLogout}>
                          <i className="fas fa-sign-out-alt mr-1"></i>
                          <Translater text="shared.header.logout" />
                        </Nav.Link>
                      </LinkContainer>
                    </>
                  );
                }}
              />
              {currentUser === null && (
                <Link
                  to="/login/"
                  className={`nav-link ${getClassNamesForMenu("login")}`}
                >
                  <i className="fas fa-sign-in-alt mr-1"></i>
                  <Translater text="shared.header.login" />
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
