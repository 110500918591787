import React, { Component, useEffect, useState } from "react";

import { Route, Switch } from 'react-router-dom';
import LoginPage from '../../pages/login-page/';
import LogoutPage from '../../pages/logout-page/';
import MainPage from '../../pages/main-page';
import UsersPage from '../../pages/user-page';
import PrincipalsPage from '../../pages/principal-page';
import PrincipalsEditorPage from '../../pages/principal-page/principal-editor';
import SekuritiesGroupPage from '../../pages/sekurity-group-page';
import SekurityEditorPage from '../../pages/sekurity-group-page/sekurity-group-editor';
import UsersEditor from '../../pages/user-page/user-editor';
import UserChangePasswordAdmin from '../../pages/user-page/user-change-password-admin';
import AccountPage from '../../pages/account-page';
import Header from '../shared/header/';
import Footer from '../shared/footer/';
import { setCurrentLocale } from 'react-easy-i18n';
import { authenticationService } from '../../services/authenticationService';
import SensorSettingPage from '../../pages/sensor-setting-page';
import SensorSettingEditor from '../../pages/sensor-setting-page/sensor-setting-editor';
import SensorShowPage from '../../pages/sensor-setting-page/sensor-show-page';
import PrivateRoute from '../shared/private-route/';
import SensorReportPage from '../../pages/sensor-report-page';


const  App = () => {
  
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('lang') || 'EN');
  const [isLoaded, setIsLoaded] = useState(false); 
  const [, setCurrentUser] = useState('');

  useEffect(() => {     
    setCurrentLocale(currentLanguage.toLowerCase());

    const getConfiguration = async () => {
        //TODO;
        setIsLoaded(true); 
    }        
    getConfiguration(); 

    authenticationService.currentUser.subscribe(x => {  
        setCurrentUser(x);
    }); 
    }, []);

  const onLanguageChange = (lang) => {   
    localStorage.setItem('lang', lang);
    setCurrentLanguage(lang); 
    setCurrentLocale(lang.toLowerCase());        
} 

    return (
      isLoaded === true ? 
      <>
      <Header lang={currentLanguage} onLanguageChange={onLanguageChange} /> 
      <Switch>
        <PrivateRoute exact path="/" component={MainPage} />
        <PrivateRoute exact path="/map/event/:id" component={MainPage} />
        <PrivateRoute exact path="/map/" component={MainPage} />

        <PrivateRoute exact path="/configuration/users" component={UsersPage} />
        <PrivateRoute path="/configuration/users/edit/" component={UsersEditor} />
        <PrivateRoute path="/configuration/users/create/" component={UsersEditor}/>
        <PrivateRoute path="/configuration/users/changePassword/" component={UserChangePasswordAdmin}/>
        <PrivateRoute exact path="/configuration/principals/" component={PrincipalsPage}/>
        <PrivateRoute path="/configuration/principals/edit/:id" component={PrincipalsEditorPage}/>
        <PrivateRoute path="/configuration/principals/create" component={PrincipalsEditorPage}/>
        
        <PrivateRoute exact path="/configuration/sec-groups/" component={SekuritiesGroupPage}/>
        <PrivateRoute path="/configuration/sec-groups/edit/" component={SekurityEditorPage}/>
        <PrivateRoute path="/configuration/sec-groups/create" component={SekurityEditorPage}/>
        <PrivateRoute path="/account/" component={AccountPage}/>
        <PrivateRoute exact path="/sensor/settings/" component={SensorSettingPage}/>
        <PrivateRoute exact path="/sensor/settings/edit/:id" component={SensorSettingEditor}/>
        <PrivateRoute exact path="/sensor/settings/show/:id" component={SensorShowPage}/>
        <PrivateRoute exact path="/sensor/stat/:id" component={SensorReportPage}/>

        <Route exact path="/login/" component={LoginPage} />
        <Route exact path="/logout/" component={LogoutPage} />
      </Switch>
      <Footer/>
      </> : 
      <div className="loading-app">            
      Loading app...                
 </div>


    );
  
} 

export default App;
