import React from 'react';
 
const CheckboxControl = ({ name, value = false, className, onChange }) => {     
    return ( 
        <input 
            type="checkbox"  
            className={className || 'form-check-input'} 
            autoComplete="false" 
            name={name} 
            id={name}
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
        />
    )
}

export default CheckboxControl;