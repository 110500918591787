import React from 'react';

const TextControl = ({ name, value, className, onChange }) => {     
    const _value = value === null || value === undefined ? '' : value;

    return (
        <input 
            type="text"
            autoComplete="off"
            className={className || 'form-control'} 
            name={name} 
            value={_value}   
            onChange={(e) => onChange(e.target.value)} /> 
    )
}

export default TextControl;