
import React from "react";
import { Marker} from "react-leaflet";

const ActiveMarker = props => {

    const initMarker = ref => {
      if (ref) {
        setTimeout(() => {
            ref.leafletElement.openPopup()
        }, 150);
      }
    }
  
    return <Marker ref={initMarker} {...props}/>
  }

  export default ActiveMarker;