
import React, { useState, useEffect } from "react";
import { PrincipalsService } from "../../../../services";
import FormActionsRow from "../../../../components/shared/form-actions-row";
import FormGroup from "../../../../components/shared/form-group";
import { withRouter } from "react-router-dom";
import { FormCheck } from "react-bootstrap";



const PrincipalEditorSmsSetting = props => {
    const principalid = props.match.params.id;
    const isNewPrincipal = principalid === undefined || principalid.length !== 36;

    const _principalsService = new PrincipalsService();


    const [name, setName] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [maxSms, setMaxSms] = useState(0);
    const [smsSended, setSmsSended] = useState (0);
    const [enable, setEnable] = useState (false);
    
  
    const [message, setMessage] = useState("");
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!isNewPrincipal) {
            _principalsService.getItem("/SmsSetting/" + principalid).then(result => {
              result = result.result;
              console.log(result);
              setSmsSended(result.currentSmsCounter == null ? 0 : result.currentSmsCounter);
              setMaxSms(result.maxSms);
              setApiKey(result.apiKey);
              setLoaded(true);
            });
          } else {
            setLoaded(true);
          }
    }, []);

    const handleChanges = () => {
        if (!isNewPrincipal) {
          setMessage("Changes Unsaved");
        }
      };
    
    const handleSwitch = (e) => {
        setEnable(!enable);
      };

      const submit = e => {
        setIsFormSubmitting(true);
        e.preventDefault();
    
        if (isFormSubmitting) {
          return;
        }
    
        if (isNewPrincipal) {
          return(<></>);
        } else {
          _principalsService
            .updateItemWithSubPath("SmsSetting",{
              id: principalid,
              apiKey: apiKey,
              active: enable,
              maxSms: parseInt(maxSms)
            })
            .then(result => {
              if (result.isSuccess == true) {
                setMessage("SMS Provider Settings were updated");
                setIsFormSubmitting(false);
              } else {
                setMessage("Error");
                setIsFormSubmitting(false);
              }
            });
        }    
      };

      return(  

        <form onSubmit={submit} className="pt-2">
        <div className="row">
          <div className="col-12 col-md-4 mb-5">
            <FormGroup label="principalEditor.smsproviderkey">
              <input
                type="password"
                autoComplete="off"
                className="form-control"
                name="apiKey"
                value={apiKey || ""}
                onChange={e => {
                  setApiKey(e.target.value);
                  handleChanges();
                }}
              />
            </FormGroup>
            <FormGroup label="principalEditor.maxSms">
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                name="key"
                value={maxSms || ""}
                onChange={e => {
                  setMaxSms(e.target.value);
                  handleChanges();
                }}
              />
            </FormGroup>
            <FormCheck className="mb-2"
                id="switchEnabled"
                type="switch"
                checked={enable}
                label="Enable"
                onClick={e => handleSwitch(e)}
            />
      

            {parseInt(maxSms) > 1 ? (
              <>
                <div>SMS sended {smsSended}</div>
                <div>
                  SMS left {parseInt(maxSms) - parseInt(smsSended)}
                </div>
              </>
            ) : (
              <div></div>
            )}
            <h5>{message}</h5>
            <FormActionsRow
              onFormSubmit={submit}
              isFormSubmitting={isFormSubmitting}
              goBackUrl="/configuration/principals/"
            ></FormActionsRow>
          </div>
        </div>
      </form>
    ) 

};

export default withRouter(PrincipalEditorSmsSetting);