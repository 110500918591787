import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { SecurityGroupsService } from "../../services";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { LinkContainer } from "react-router-bootstrap";
import Translater from '../../components/shared/translater';
import Pagnation from '../../components/shared/pagination';
import queryString from "query-string";
//new import
import AccessBoundary from "../../components/shared/access-boundary/index";
//new import


const SekuritiesPage = (props) => {

  const query = queryString.parse(props.location.search);
  const _securityGroupsService = new SecurityGroupsService();

  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    _securityGroupsService.getItems().then(result => {
      result = result.result;
      setUserList(result);
      if(query.page !== undefined || query.page === null ) {
        
        
        setCurrentPage(parseInt(query.page));
      }
        
      setLoading(false);
    });

    //setUserList(_usersService.getItems());
    setLoading(false);
  }, []);
  
  const onPagechange = ((val)=>{
    setCurrentPage(val);
  });

  return (
    <> 
      <div className="mt-3 container ">
        <h2><Translater text="page.group.title"/></h2>
        <Table striped bordered hover className="mt-3">
          <thead className="thead-dark">
            <tr>
                          <th><Translater text="page.group.name" /></th>
                          <AccessBoundary
                              roles={["GLOBAL_ADMIN"]}
                              content={() => {
                                  return (
                                      <>
                                          <th><Translater text="page.group.action" /></th>
                                      </>
                                  );
                              }}
                          />
              
            </tr>
          </thead>
          <tbody>
            {userList.map(user => {
              return (
                <tr>
                      <td>{user.name}</td>
                      <AccessBoundary
                          roles={["GLOBAL_ADMIN"]}
                          content={() => {
                              return (
                                  <>
                                  <td>
                                    <ButtonToolbar className="ml-2">
                                      <LinkContainer
                                        to={"/configuration/sec-groups/edit/" + user.id}
                                      >
                                        <Button variant="primary"><Translater text="shared.edit"/></Button>
                                      </LinkContainer>
                                      <Button variant="danger" className="ml-2" onClick={e => {_securityGroupsService.deleteItem("/"+user.id).then(result => {window.location.reload();})} }>
                                        <Translater text="shared.delete"/>
                                      </Button>
                                    </ButtonToolbar>
                                    </td>
                                  </>
                              );
                          }}
                      />

                </tr>
              );
            })}
          </tbody>
        </Table>
              <Pagnation pagesCount={pageCount} itemsCount={itemsCount} selectedPage={currentPage} onPageChange={val => onPagechange(val)} />
              <AccessBoundary roles={["GLOBAL_ADMIN"]} content={() => { return ( <>
                              <LinkContainer to={"/configuration/sec-groups/create/"}>
                                  <Button variant="success"><Translater text="page.group.add" /></Button>
                              </LinkContainer>
                          </>); }} />

      </div>
    </>
  );
};

export default withRouter(SekuritiesPage);
