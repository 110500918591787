import React from 'react';  

import translate from '../../../core/translate'; 
import decodeHtml from '../../../core/decode-html'; 
import '../../../i18n/en';
import '../../../i18n/de'; 

const Translater = ({ text, html }) => {   
    let result;
    let translation = translate(text);
     
    if (html === true)
    { 
        const decodedTranslation = decodeHtml(translation);         
        result = <span dangerouslySetInnerHTML={{__html: `${decodedTranslation}`}}></span>
    }
    else {
        result = <>{translation}</>
    }
        
    return result;
}

export default Translater;