import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  UsersService,
  PrincipalsService,
  ChangePrincipleService,
  CurrentPrincipleService
} from "../../services";
import Table from "react-bootstrap/Table";
import Header from "../../components/shared/header";
import Footer from "../../components/shared/footer";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { LinkContainer } from "react-router-bootstrap";
import Translater from "../../components/shared/translater";

const PrincipalsPage = () => {
  const _principalsService = new PrincipalsService();
  const _changePrincipleService = new ChangePrincipleService();
  const _currentPrincipleService = new CurrentPrincipleService();

  const [principalList, setPrincipalList] = useState([]);
  const [currentPrincipleName, setCurrentPrincipleName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    _principalsService.getItems().then(result => {
      result = result.result;
      setPrincipalList(result);
      setLoading(false);
    });
    _currentPrincipleService.getItems().then(result => {
      result = result.result;
      setCurrentPrincipleName(result.name);
    });
  }, []);

  return (
    <>
      <div className="mt-3 container ">
        <h2 className="mb-3">
          <Translater text="page.principal.title" /> {currentPrincipleName}
        </h2>
        <Table striped bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {principalList.map(p => {
              return (
                <tr>
                  <td>{p.name}</td>
                  <td>
                    <ButtonToolbar className="ml-2">
                      <LinkContainer
                        to={"/configuration/principals/edit/" + p.id}
                      >
                        <Button variant="primary">
                          <Translater text="shared.edit" />
                        </Button>
                      </LinkContainer>
                      {p.name != currentPrincipleName ? (
                        <Button
                          variant="primary"
                          className="ml-2"
                          onClick={e => {
                            _changePrincipleService
                              .getItem(p.id)
                              .then(result => {
                                window.location.reload();
                              });
                          }}
                        >
                          <Translater text="page.principal.set" />
                        </Button>
                      ) : (
                        <></>
                      )}

                      <Button
                        variant="danger"
                        className="ml-2"
                        onClick={e => {
                          _principalsService
                            .deleteItem("/?id=" + p.id)
                            .then(result => {
                              window.location.reload();
                            });
                        }}
                      >
                        <Translater text="shared.delete" />
                      </Button>
                    </ButtonToolbar>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <LinkContainer to={"/configuration/principals/create/"}>
          <Button variant="success">
            <Translater text="page.principal.add" />
          </Button>
        </LinkContainer>
      </div>
    </>
  );
};

export default withRouter(PrincipalsPage);
