import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  ReadingService,
  ConfirmAlertService,
  ListSensorService
} from "../../../services";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Translater from "../../../components/shared/translater";
import Pagnation from "../../../components/shared/pagination";
import Moment from "react-moment";

const SensorShowPage = props => {
  const MAX_BATTERY = 415; //4,15 V
  const LOW_BATTERY = 365; //3,65 V
  const MAX_LOW_BATTERY_DELTA = MAX_BATTERY - LOW_BATTERY;
  const _readingService = new ReadingService();
  const _confirmAlertService = new ConfirmAlertService();

  const [sensorList, setSensorList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [name, setName] = useState();
  const [itemsCount, setItemsCount] = useState(0);

  const itemsPerPageCount = 6;

  const _listSensorService = new ListSensorService();

  useEffect(() => {
    _listSensorService.getItems().then(result => {
      result = result.result;
      var result = result.find(p => p.id == props.match.params.id);
      setName(
        result.alias == null || result.alias == ""
          ? result.sensorIdentifier
          : result.alias
      );
      _readingService
        .getItem("/" + props.match.params.id + "?meta=true")
        .then(result => {
          setItemsCount(result.count);
          setPageCount(Math.ceil(result.count / itemsPerPageCount));
          _readingService
            .getItem(
              "/" +
                props.match.params.id +
                "?page=" +
                currentPage +
                "&itemsPerPageCount=" +
                itemsPerPageCount
            )
            .then(result => {
              result = result.result;
              setSensorList(result);
            });
        });
    });
  }, []);

  const onPagechange = val => {
    setCurrentPage(val);
    _readingService
      .getItem(
        "/" +
          props.match.params.id +
          "?page=" +
          val +
          "&itemsPerPageCount=" +
          itemsPerPageCount
      )
      .then(result => {
        result = result.result;
        setSensorList(result);
      });
  };

  const displayBatteryLevel = function(batterylevel) {
    if (batterylevel < LOW_BATTERY) {
      return "0%";
    }
    if (batterylevel > MAX_BATTERY) {
      return "100%";
    }

    var battery =
      Math.ceil(((batterylevel - LOW_BATTERY) / MAX_LOW_BATTERY_DELTA) * 100) +
      "%";
    return battery;
  };

  const handleAlertConfirm = function(val) {
    _confirmAlertService.createItem({ id: val }).then(result => {
      onPagechange(currentPage);
    });
    //TODO confirm this alert and send all a sms
  };

  return (
    <div className="mt-3 container ">
      <h2>
        <Translater text="page.sensorshow.title" /> {name}
      </h2>
      <Table striped bordered hover className="mt-3">
        <tbody>
          {sensorList.map(p => {
            return (
              <tr>
                <td>
                  {p.type == 2 || p.type == 22 ? (
                    <>
                      <i className="fas fa-circle text-danger"></i>
                      <b className="text-danger">
                        <Translater text="page.sensorshow.alert" />{" "}
                        {p.typ == 2 ? (
                          <Translater text="page.sensorshow.unconfirmed" />
                        ) : (
                          <Translater text="page.sensorshow.confirmed" />
                        )}
                      </b>
                      {p.longitude == null ||
                      p.latitude == null ||
                      p.longitude == "0" ||
                      p.latitude == "0" ? (
                        <span>
                          {" "}
                          <Translater text="page.sensorshow.nogpsdata" />{" "}
                        </span>
                      ) : (
                        <span>
                          {" "}
                          <b>
                            <a
                              href={
                                "https://www.openstreetmap.org/?mlat=" +
                                p.latitude +
                                "&mlon=" +
                                p.longitude +
                                "&zoom=12"
                              }
                            >
                              <Translater text="page.sensorshow.linkLocation" />
                            </a>
                          </b>{" "}
                        </span>
                      )}
                      at{" "}
                      <Moment format="DD.MM.YYYY HH:mm:ss">
                        {p.createdDate}
                      </Moment>
                      <span>
                        {" "}
                        {p.type == 2 ? (
                          <Button
                            className="ml-5"
                            onClick={e => handleAlertConfirm(p.id)}
                          >
                            <Translater text="page.sensorshow.confirm" />
                          </Button>
                        ) : (
                          ""
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      {p.type == 1 ? (
                        <>
                          <i className="fas fa-circle text-warning"></i>
                          <b className="text-warning">Suspicion</b>
                        </>
                      ) : (
                        <>
                          <i className="fas fa-circle text-primary"></i>
                          <b className="text-primary">Ping</b>
                        </>
                      )}
                      {p.longitude == null ||
                      p.latitude == null ||
                      p.longitude == "0" ||
                      p.latitude == "0" ? (
                        <span>
                          {" "}
                          <Translater text="page.sensorshow.nogpsdata" />{" "}
                        </span>
                      ) : (
                        <span>
                          {" "}
                          <b>
                            <a
                              href={
                                "https://www.openstreetmap.org/?mlat=" +
                                p.latitude +
                                "&mlon=" +
                                p.longitude +
                                "&zoom=12"
                              }
                            >
                              <Translater text="page.sensorshow.linklocationping" />
                            </a>
                          </b>{" "}
                        </span>
                      )}
                      at{" "}
                      <Moment format="DD.MM.YYYY HH:mm:ss">
                        {p.createdDate}
                      </Moment>
                    </>
                  )}
                  <div classname="pl-5">
                    <br></br>
                    <p>
                      <b>
                        <Translater text="page.sensorshow.co2" />:
                      </b>{" "}
                      {p.co2SingleValue} ppm<br></br>{" "}
                      <b>
                        <Translater text="page.sensorshow.dust" />:
                      </b>{" "}
                      {p.fineDust25SingleValue} µg/m³{" "}
                    </p>
                    <b>
                      {" "}
                      <Translater text="page.sensorshow.batterylevel" />:
                    </b>{" "}
                    {displayBatteryLevel(p.batteryLevel)} ({p.batteryLevel * 10}{" "}
                    mV)
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Pagnation
        pagesCount={pageCount}
        itemsCount={itemsCount}
        selectedPage={currentPage}
        onPageChange={val => onPagechange(val)}
      />
    </div>
  );
};

export default withRouter(SensorShowPage);
