import React from 'react'; 

const NotAuthorizedPage = () => {
    return (
        <>
        <div className="not-authorized-page">
            Zugriff verweigert
        </div>
        </>
    );
}

export default NotAuthorizedPage;