import React from 'react';
import { Text } from 'react-easy-i18n';


const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <hr />
                <div className="row">
                    <div className="col-6">
                        &copy; Kontacts
                        <br />
                        <a href="/">
                            
                        </a>
                    </div>
                    <div className="col-6 text-right">
                        
                    </div>
                </div>
            </div>
        </footer>
    );
} 

export default Footer;