import React, { useState, useEffect } from "react";
import FormActionsRow from "../../../components/shared/form-actions-row";
import FormGroup from "../../../components/shared/form-group";
import queryString from "query-string";
import { PrincipalsService } from "../../../services";
import { Tabs, Tab, Form, FormCheck } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import PrincipalEditorMainSetting from "./principal-editor-mainsetting";
import PrincipalEditorSmsSetting from "./principal-editor-smssetting";
import PrincipalEditorEmailSetting from "./principal-editor-emailsetting";


const PrincipalEditor = props => {
  const principalid = props.match.params.id;

  const isNewPrincipal = principalid === undefined || principalid.length !== 36;

  const [name, setName] = useState("");
  const [key, setKey] = useState("");
  const [smsSended, setSmsSended] = useState("");
  const [maxSms, setMaxSms] = useState("");

  const [message, setMessage] = useState("");
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const _principalsService = new PrincipalsService();

  const [tabKey, setTabKey] = useState("mainComponent");

  useEffect(() => {
    const value = queryString.parse(props.location.search);

    if (!isNewPrincipal) {
      _principalsService.getItem("/MainSetting/" + principalid).then(result => {
        result = result.result;
        setName(result.name);
        setKey(result.key);
        setSmsSended(result.smsSended);
        setMaxSms(result.maxSms);
        setLoaded(true);
      });
    } else {
      setLoaded(true);
    }
  }, []);

  return (
    <>
      {loaded ? (
        <div>
        <div className="user-editor justify-content-center container">
        <h2 className="pt-3">{isNewPrincipal ? "Add" : "Edit"} Principal {name}</h2>
          <Tabs
            id="controlled-tab-example"
            activeKey={tabKey}
            onSelect={k => setTabKey(k)}
            className="pt-3 mb-3"
          >
            <Tab eventKey="mainComponent" title="Main Settings">
              <PrincipalEditorMainSetting />
            </Tab>
            <Tab eventKey="smsComponent" title="SMS Provider Settings">
              <PrincipalEditorSmsSetting />
            </Tab>
            <Tab eventKey="emailComponent" title="Email Provider Settings">
              <PrincipalEditorEmailSetting/>
            </Tab>
          </Tabs>
        </div>
        </div>  
      ) : (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </>
  );
};

export default withRouter(PrincipalEditor);
