import React from "react";
import { useState, useEffect, useCallback } from "react";

import { withRouter, Link } from "react-router-dom";
import "./leaflet.css";
import { Map, Marker, Popup, TileLayer, ZoomControl } from "react-leaflet";
import { ListSensorService, ReadingService } from "../../services";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";
import ActiveMarker from "./components/ActiveMarker";
import Moment from "react-moment";
import useStateWithCallback from "use-state-with-callback";
//new import
import AccessBoundary from "../../components/shared/access-boundary/index";
//new import


const MainPage = () => {
  const _listSensorService = new ListSensorService();

  const _readingService = new ReadingService();

  const [center, setCenter] = useState([52.5157, 13.4992]);

  const [sensorList, setSensorList] = useState([]);


  useEffect(() => {
    _listSensorService.getItems().then(result => {
      updateDetails(
        result.result.map(p => {
          return { ...p, active: false };
        })
      );
      // updateDetails();
    });
  }, []);

    const updateDetails = list => {
        
    list.forEach(e => {
      _readingService
        .getItem("/" + e.id + "?page=" + 1 + "&itemsPerPageCount=" + 10)
        .then(result => {
          result = result.result;
            var value = list.slice();
          value.forEach(p => {
              if (p.id == e.id) {
                  if (result[0]) {
                      p.lastResponse = result[0].createdDate;
                      p.batteryLevel = result[0].batteryLevel;
                      p.fineDust25SingleValue = result[0].fineDust25SingleValue;
                      p.co2SingleValue = result[0].co2SingleValue;
                      p.ago = displayAsTimeAgo(result[0].createdDate);
                      p.signalstrength = result[0].signalstrength;
                      //sidhant code start
                      p.typ = result[0].type;
                      //sidhant code end 
                  }
            }
          });
          setSensorList(value);
        });
    });
    };

   

  const sensorWorking = date => {
    var moment = require("moment");
    var dateToCompare = moment(date);
    //var currentDate = moment().subtract(30, "minutes");
      //sidhant code start
      var currentDate = moment().subtract(420, "minutes");
      //sidhant code end
      if (dateToCompare > currentDate) {
      return true;
      }
    return false;
  };

  const displayAsTimeAgo = date => {
    var moment = require("moment");
    var dateToCompare = moment(date);
    var currentDate = moment();

    var duration = moment.duration(currentDate.diff(dateToCompare));

    var minutes = duration.asMinutes();

    if (minutes < 300) {
      return Math.ceil(minutes) + " minutes";
    }

    var hours = duration.asHours();

    if (hours < 48) {
      return Math.floor(hours) + " hours";
    }

    var days = duration.asDays();

    return Math.floor(days) + " days";

  };

    const handleSensorChange = e => {
    var value = sensorList.slice();
    value.forEach(p => {
      p.id == e.id ? (p.active = true) : (p.active = false);
    });
    setCenter([e.latitude, e.longitude]);
    updateDetails(value);
  };

  const markers = sensorList.map(e => {
    const popup = (
      <Popup>
            <table>
                <tbody>

                    <tr>
                        <td>{e.sensorIdentifier}</td>
                    <AccessBoundary
                        roles={["GLOBAL_ADMIN"]}
                        content={() => {
                            return (
                                <>
                
                    <td><Link to={"/sensor/settings/edit/" + e.id}>Edit</Link></td>
                                   
                                </>
                            );
                        }}
                        />
                    </tr>
                    <tr>
                        <td>Alias:</td>
                        <td>{e.alias} </td>
                    </tr>

          <tr>
            <td>Lat:</td>
            <td>{e.latitude} </td>
          </tr>
          <tr>
            <td>Long:</td>
            <td>{e.longitude}</td>
          </tr>
          <tr>
            <td>Last response:</td>
            <td>
              {e.lastResponse != undefined ? (
                <Moment format="DD.MM.YYYY hh:mm a">{e.lastResponse}</Moment>
              ) : (
                "Loading"
              )}{" "}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              ({e.ago} ago)
            </td>
          </tr>
          <tr>
            <td>Battery Level:</td>
            <td>{e.batteryLevel * 10} mV</td>
          </tr>
          <tr>
            <td>Co2 Level:</td>
            <td>{e.co2SingleValue} ppm</td>
          </tr>
          <tr>
            <td>Fine dust:</td>
            <td>{e.fineDust25SingleValue} µg/m³</td>
           </tr>
           <tr>
            <td>Signal Strength:</td>
            <td>{e.signalstrength}</td>
                </tr>
                </tbody>
        </table>
      </Popup>
    );

    if (e.active) {
      return (
        <ActiveMarker key={e.id} position={[e.latitude, e.longitude]}>
          {popup}
        </ActiveMarker>
      );
    }
    return (
      <Marker key={e.id} position={[e.latitude, e.longitude]}>
        {popup}
      </Marker>
    );
  });

  return (
    <>
      <Container className="mt-3">
        <Row>
          <Col xs={12} md={8}>
            <Map center={center} zoom={15} zoomControl={false}>
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <TileLayer
                url="http://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png"
                attribution='<a href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>, Style: <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA 2.0</a> <a href="http://www.openrailwaymap.org/">OpenRailwayMap</a> and OpenStreetMap'
                minZoom="2"
                maxZoom="19"
                tileSize="256"
              />
              {markers}
              <ZoomControl position="topright" />
            </Map>
          </Col>
          <Col>
            <div className="right">
              <Container>
                <Row>
                  <Col xs={12}>
                    <div className="halfheight">
                      <h3>Devices</h3>
                      <ListGroup as="ul">
                        {sensorList.map(e => {
                          return (
                            <ListGroup.Item
                              as="li"
                              action
                              onClick={() => {
                                handleSensorChange(e);
                              }}
                              active={e.active}
                                  variant={                                      !sensorWorking(e.lastResponse) ? "dark" : e.typ == 0 ? "" : e.typ == 1 ? "warning" : e.typ == 2 ? "danger" : ""                              }
                            >
                              {e.alias == null ? e.sensorIdentifier : e.alias}
                              {sensorWorking(e.lastResponse)
                                ? ""
                                      : " over " + e.ago + " inactive"}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </div>
                  </Col>
                </Row>
                <Row></Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(MainPage);
