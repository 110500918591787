import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { UsersService } from "../../services";
import Table from "react-bootstrap/Table";
import Header from "../../components/shared/header";
import Footer from "../../components/shared/footer";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { LinkContainer } from "react-router-bootstrap";
import Translater from "../../components/shared/translater";
import { authenticationService } from '../../services/authenticationService';
//new import
import AccessBoundary from "../../components/shared/access-boundary/index";
//new import


const UsersPage = () => {
  const _usersService = new UsersService();

  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const isGlobalAdmin = authenticationService.isUserInRoles(["GLOBAL_ADMIN"]);

  useEffect(() => {
    _usersService.getItems().then(result => {
      result = result.result;
      setUserList(result);
      setLoading(false);
    });

    //setUserList(_usersService.getItems());
    setLoading(false);
  }, []);


  const deleteUser = (id) => {
    _usersService.deleteItem("/" +id).then(result => {window.location.reload();});
  };


  return (
    <>
      <div className="mt-3 container ">
        <h2><Translater text="page.user.title"/></h2>
        <Table striped bordered hover className="mt-3">
          <thead className="thead-dark">
            <tr>
              <th><Translater text="page.user.username"/></th>
              <th><Translater text="page.user.role"/></th>
              <th><Translater text="page.user.group"/></th>

                <AccessBoundary
                    roles={["GLOBAL_ADMIN"]}
                    content={() => {
                        return (
                            <>
                                <th><Translater text="page.sensorsetting.action" /></th>
                            </>
                        );
                    }}
                />
            </tr>
          </thead>
          <tbody>
            {userList.map(user => {
              return (
                <tr>
                  <td>{user.userName}</td>
                  <td> <Translater text={"shared." + user.role}/></td>
                  <td>{(user.securityGroups.map(p => p.name).join(", "))}</td>

                <AccessBoundary
                    roles={["GLOBAL_ADMIN"]}
                    content={() => {
                        return (
                            <>
                      <td>
                        { (user.role == "GLOBAL_ADMIN" && !isGlobalAdmin ) ? <></> :
                    
                        <ButtonToolbar className="ml-2">
                          <LinkContainer
                            to={"/configuration/users/edit/" + user.id}
                          >
                          <Button variant="primary" className="ml-2"><Translater text="shared.edit"/></Button>
                          </LinkContainer>
                          <LinkContainer
                            to={"/configuration/users/changePassword/" + user.id}
                          >
                            <Button variant="primary" className="ml-2"><Translater text="page.user.changepassword"/></Button>
                          </LinkContainer>
  
                          <Button variant="danger" className="ml-2" onClick={e => { deleteUser(user.id) } }>
                          <Translater text="shared.delete"/>
                          </Button>
                        </ButtonToolbar>
                         }
                    </td>

                            </>
                        );
                    }}
                />

                </tr>
              );
            })}
          </tbody>
        </Table>
              

                  <AccessBoundary
                      roles={["GLOBAL_ADMIN"]}
                      content={() => {
                          return (
                              <>
                                  <LinkContainer to={"/configuration/users/create/"}>
                                     <Button variant="success"><Translater text="page.user.add" /></Button>
                                  </LinkContainer>
                              </>
                          );
                      }}
                  />

                  


        
      </div>
      <Footer />
    </>
  );
};

export default withRouter(UsersPage);
