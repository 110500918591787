import React, { useState, useEffect } from 'react';
import LoginPageView from './login-page-view'; 
import { withRouter, Redirect } from 'react-router-dom';
import { history } from "../../core/history";   

import { authenticationService } from '../../services/authenticationService';
  
const LoginPage = () => {    
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [message, setMessage] = useState(null);
    const [redirectToMainPage, setRedirectToMainPage] = useState(false);

    useEffect(() => {        
        document.title = `Login / Frog`;  

        return (() => {
            document.title = `Frog`;  
        });
    }, []);
 
    const onFormSubmit = async ({username, password}) => {  
        setIsFormSubmitting(true);
        const loginResult = await authenticationService.login(username, password);
 
         
        if (loginResult.isSuccess === false) {
            setIsFormSubmitting(false); 
            setMessage(loginResult.result.message);   
             
        }
        if (loginResult.isSuccess === true){
            setRedirectToMainPage(true); 
        } 
    }

    //const result = authenticationService.currentUserValue ? <Redirect to='/home'/> :
    //                            <>
    //                            <LoginPageView   
    //                                isFormSubmitting = {isFormSubmitting}
    //                                message = {message}
    //                                onFormSubmit={onFormSubmit} />
    //                                {redirectToMainPage ?  <Redirect to='/home' /> : <></>} 
    //                            </>;


    const result = authenticationService.currentUserValue ? <Redirect to='/map' /> :
                                <>
                                    <LoginPageView
                                        isFormSubmitting={isFormSubmitting}
                                        message={message}
                                        onFormSubmit={onFormSubmit} />
            {redirectToMainPage ? <Redirect to='/map' /> : <></>}
                                </>;

    return(result);
}
 
export default withRouter(LoginPage); 