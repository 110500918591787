import { registerLang } from 'react-easy-i18n';
 
registerLang('en', {
    shared: {
        show: "Show",
        SEC_MANAGER: 'Manager',
        SEC_ADMIN: 'Local Admin',
        GLOBAL_ADMIN: 'Global Admin',
        SEC_VIEWER: 'Viewer',
        edit: "Edit",
        delete: "Delete",
        showstat: "Display in Chart",
        from: 'From',
        to: 'To',
        
        header: 
        {

            mainpage: 'Map',
            configuration: 'Configuration',
            principal: 'Principal', 
            user: 'User',
            sekuritygroup: 'Group',
            logout: 'Logout',
            login: 'Login',
            sensor: 'Sensor',
            settings: 'Settings'
            
        },
        footer: {
            help: 'Help'
        },
        formActionRow: {
            saving: 'Saving...',
            save: 'Save',
            backTo: 'back to list',
            cancel: 'Cancel'
        },
        toast: {
            successSaved: 'Saved!'
        },
        loader: {
            dataIsLoading: 'Please wait, data is loading...'
        },
    }, 
    mainpage: {
        title: 'Welcome to Graffiti Detection Service',
        gotosensor: 'Go to Devices'
    },
    principal: {
        title: 'Principal ',
        set: 'Select',
        add: 'Add'
    },
    sensorreport:
    {
        notlive: 'Goto to live mode',
        live: 'Deactivate live mode',
        clearfilter: 'Clear filter',
        batteryLevel: 'Battery level',
        measurements: 'Measurements',

    },
    principalEditor: {
        name: 'Name ',
        key: 'AES Shared Key',
        maxSms: 'Booked Sms',
        smsproviderkey: 'ApiKey',
        active: 'Active',
        server: 'Server',
        port: 'Port',
        username: 'Username',
        password: 'Password'
    },
    secGroupEditor: {
        name: 'Name'
    },
    sensorsetting: {
        sensorname: "Sensor",
        groupname: "Group",
        action: "Action",
        alias: "Alias",
        nogroup: "-"
    },
    sensorshow: {
        title: "Device",
        alertconfirmed: "Alert confirmed",
        linkLocation: "Map",
        confirmed: "confirmed",
        unconfirmed: "unconfirmed",
        dust: "fine dust (PM 2.5)",
        co2: "CO2",
        batterylevel: "Battery",
        nogpsdata: "No GPS-data",
        confirm: "Confirm as inspected",
        alert: "Alert",
        linklocationping: "Map"
    },
    user: {
        title: 'User Managment',
        username: 'Username',
        action: 'Action',
        changepassword: 'Change Password',
        add: 'Add',
        role: 'Role',
        group: 'Group'
    },
    sensorEditor: {
        Group: "Group",
        title: "Sensor settings",
        alias: "Alias",
        latitude: "Latitude",
      longitude: "Longitude",
      co2Threshold: "Carbon Dioxide Threshold",
      dustThreshold: "Fine Dust Threshold"
    },
    userEditor: {
        phonenumber: 'Phone number',
        group: 'Security Group',
        password1: 'Password',
        password2: 'Repeat Password'
    },
    group: {
        title: 'Security Group Managment',
        add: 'Add new Security Group',
        action: 'Action',
        name: 'Name',
        changepassword: 'Change Password',
        add: 'Add new Group'
    },
    loginPage: {
        loginText: 'Please sign in',
        username: 'Username',
        password: 'Password',
        login: 'Login',
        loggining: 'Signing in...',
        messages: 
        {
            'invalidUsernameOrPassword': 'Invalid username or password'
        }
    },
    usersPage: {
        title: 'Users',
        table: {
            name: 'Name',
            username: 'Username',
            roles: 'Roles',
            email: 'Email',
            company: 'Company',            
            edit: 'Edit',            
            resetPassword: 'Reset password'
        },
        add: 'Add'
    },
    userEditor: {
        email: 'Email',
        username: 'Username',
        firstName: 'First name',
        lastName: 'Last name',
        password: 'Password',
        password1: 'Password',
        password2: 'Repeat Password',
        phonenumber: 'Phone number',
        group: 'Group', 
        company: 'Company',
        roles: 'Roles',
        validationErrors: {
            emailExists: 'Email exists',
            usernameExists: 'Username exists',
            companyIdIsRequired: 'Company is required',
            roleNamesAreRequired: 'Role is required',
            roleIsNotAvailiable: 'Role is not available'
        }
    },
    userAddPage: {
        title: 'Add User'
    },
    userEditPage: {
        title: 'Edit User'
    }
});