import React, { useState, useEffect } from "react";
import FormActionsRow from "../../../components/shared/form-actions-row";
import FormGroup from "../../../components/shared/form-group";
import queryString from "query-string";
import Select from "react-select";
import Header from "../../../components/shared/header";
import Footer from "../../../components/shared/footer";
import { UsersService, SekurityGroupsService, UserChangePasswordAdminService } from "../../../services";

const UserChangePasswordAdmin = props => {
  const userid = props.location.pathname.substring(
    props.location.pathname.lastIndexOf("/") + 1
  );


  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");


  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const _usersService = new UsersService();
  const _userChangePasswordAdminService = new UserChangePasswordAdminService();


  useEffect(() => {
    _usersService.getItem("/" + userid).then(result => {
      result = result.result;
      
      setUsername(result.userName);
      setLoaded(true);
    });
  }, []);


  const submit = e => {
    e.preventDefault();

    if (isFormSubmitting) {
      return;
    }

    _userChangePasswordAdminService
      .createItem({
        username: username,
        password: password
      })
      .then(result => {
        console.log("Return");
        //console.log(result);
        if (result === "200") {
          setMessage("Password was changed");
        }
      });
    setIsFormSubmitting(true);

    setIsFormSubmitting(false);
  };

  return (
    <>
      {loaded ? (
        <div className="user-editor justify-content-center container">
<h1>Change Password for User "{username}"</h1>
          <form onSubmit={submit}>
            <div className="row">
              <div className="col-12 col-md-4 mb-5">
                <FormGroup label="userEditor.password">
                  <input
                    type="password"
                    autoComplete="off"
                    className="form-control"
                    name="password"
                    value={password || ""}
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                  />
                </FormGroup>
                <h5>{message}</h5>
                <FormActionsRow
                  onFormSubmit={submit}
                  isFormSubmitting={isFormSubmitting}
                  goBackUrl="/configuration/users/"
                ></FormActionsRow>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </>
  );
};

export default UserChangePasswordAdmin;
