import React from 'react';
import Translater from '../translater/';
import { TextControl, PasswordControl, CheckboxControl, SelectControl } from '../editor-controls/';



const FromGroup = ({ type, label, children, name, value, options, onChange, hidden, className }) => {  
    let content = children;

    if (type === 'text'){
        content = <TextControl name={name} value={value} onChange={onChange} />
    }
    else if (type === 'password'){
        content = <PasswordControl name={name} value={value} onChange={onChange} />
    }
    if (type === 'select'){
        content = <SelectControl name={name} value={value} onChange={onChange} options={options} />
    }
    else if (type === 'checkbox'){
        content = <CheckboxControl name={name} value={value} onChange={onChange} />

        return (
            <div className={`form-group form-check ${hidden === true ? 'd-none' : ''} ${className}`} >  
                <label htmlFor={name} className="form-check-label" >
                    { content }
                    <Translater text={label} />
                </label>
            </div>
        )
    }

    return (
        <div className={`form-group ${hidden === true ? 'd-none' : ''} ${className}`}>
            <label htmlFor="id">
                <Translater text={label} />
            </label>
            
            { content }
        </div>
    )
}
export default FromGroup; 