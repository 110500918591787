import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { ReadingService, SensorService } from "../../services";
import Button from "react-bootstrap/Button";
import Translater from "../../components/shared/translater";
import Plot from "react-plotly.js";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
import Form from "react-bootstrap/Form";

import { registerLocale, setDefaultLocale } from "react-datepicker";

const SensorReportPage = props => {
  const _readingService = new ReadingService();
  const _sensorService = new SensorService();

  const [sensorList, setSensorList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [liveModus, setLiveModus] = useState("notlive");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [readings, setReadings] = useState([]);
  const [minutes, setMinutes] = useState(5);
  const [sensorName, setSensorName] = useState("");

  useEffect(() => {
    _sensorService.getItem("/" + props.match.params.id).then(result => {
      result = result.result;
      result.alias !== null
        ? setSensorName(result.alias)
        : setSensorName(result.identifier);
    });

    const updateData = interval => {
      _readingService
        .getItem("/" + props.match.params.id + "?itemsPerPageCount=" + 100000)
        .then(result => {
          if (props.location.pathname != props.history.location.pathname) {
            clearInterval(interval);
            return;
          }
          result = result.result;
          setReadings(result);
        });
    };
    updateData();

    const interval = setInterval(() => {
      updateData(interval);
    }, 5000);
  }, []);

  registerLocale("de", de);

  const dateIsNotOlderThanXinMinutes = (date, minutes) => {
    var moment = require("moment");
    var dateToCompare = moment(date);
    var currentDate = moment().subtract(minutes, "minutes");
    if (dateToCompare > currentDate) {
      return true;
    }
    return false;
  };

  const changeStartDate = e => {
    setStartDate(e);
  };

  const changeEndDate = e => {
    setEndDate(e);
  };

  const clearFilter = e => {
    changeStartDate(null);
    changeEndDate(null);
  };

  const handleLiveMode = () => {
    if (liveModus == "live") {
      setLiveModus("notlive");
    } else {
      setLiveModus("live");
    }
  };

  const changeIntervall = p => {
    // NaN is the only value that is not equal to itself
    let isNaN = maybeNaN => maybeNaN != maybeNaN;

    if (p == "") {
      setMinutes(1);
    } else {
      if (!isNaN(parseInt(p))) {
        setMinutes(parseInt(p));
      }
    }
  };

  const filterLive = p => {
    return (
      liveModus != "live" ||
      dateIsNotOlderThanXinMinutes(p.createdDate, minutes)
    );
  };

  const filterDate = p => {
    var moment = require("moment");
    var dateToCompare = moment(p);

    if (startDate != null) {
      if (dateToCompare < moment(startDate)) {
        return false;
      }
    }
    if (endDate != null) {
      if (dateToCompare > moment(endDate)) {
        return false;
      }
    }
    return true;
  };

  return (
    <div className="mt-3 container ">
      <h1>
        <Container>
          <Row>
            <Col></Col>
          </Row>
        </Container>
        <Translater text="page.sensorshow.title" /> {sensorName}{" "}
        <Button variant="outline-secondary" onClick={e => handleLiveMode()}>
          <Translater text={"page.sensorreport." + liveModus} />
        </Button>
      </h1>
      {liveModus != "live" ? (
        <div className="mt-5">
          <Container>
            <Row>
              <Col Col sm={3}>
                <Translater text={"shared.from"} />:
                <DatePicker
                  selected={startDate}
                  onChange={changeStartDate}
                  showTimeSelect
                  locale="de"
                  timeFormat="HH:mm"
                  dateFormat="dd.MM.yyyy HH:mm"
                  timeIntervals={15}
                />
              </Col>
              <Col Col sm={3}>
                <Translater text={"shared.to"} />:
                <DatePicker
                  selected={endDate}
                  onChange={changeEndDate}
                  showTimeSelect
                  locale="de"
                  timeFormat="HH:mm"
                  dateFormat="dd.MM.yyyy HH:mm"
                  timeIntervals={15}
                />
              </Col>
              <Col>
                <Button className="mb-3" onClick={e => clearFilter()}>
                  <Translater text={"page.sensorreport.clearfilter"} />
                </Button>
              </Col>
            </Row>
            <Row></Row>
          </Container>
        </div>
      ) : (
        <div>
          <Form.Control
            type="text"
            placeholder="Live Number"
            value={minutes}
            onChange={e => {
              changeIntervall(e.target.value);
            }}
          />
        </div>
      )}
      {readings.filter(p => filterLive(p)).length == 0 ? (
        <div>No Data in the last {minutes} minutes</div>
      ) : (
        <div>
          <h3>
            {" "}
            <Translater text={"page.sensorreport.measurements"} />
          </h3>
          <Plot
            data={[
              {
                name: "Co2 in ppm",
                x: readings
                  .filter(p => filterLive(p) && filterDate(p.createdDate))
                  .map(item => item.createdDate),
                y: readings
                  .filter(p => filterLive(p) && filterDate(p.createdDate))
                  .map(item => item.co2SingleValue),
                type: "scatter",
                line: { color: "#dd9017", width: 1 }
              },
              {
                name: "Dust in µg/m³",
                x: readings
                  .filter(p => filterLive(p) && filterDate(p.createdDate))
                  .map(item => item.createdDate),
                y: readings
                  .filter(p => filterLive(p) && filterDate(p.createdDate))
                  .map(item => item.fineDust25SingleValue),
                type: "scatter",
                line: { color: "#64dd17", width: 1 }
              }
            ]}
            layout={{
              width: 1000,
              height: 350,
              margin: { l: 50, r: 50, t: 50, b: 50 },
              legend: { orientation: "h", xanchor: "center", x: 0.5 }
            }}
          />
          <h3>
            <Translater text={"page.sensorreport.batteryLevel"} />
          </h3>

          <Plot
            data={[
              {
                name: "Battery Level in mA",
                x: readings
                  .filter(p => filterLive(p) && filterDate(p.createdDate))
                  .map(item => item.createdDate),
                y: readings
                  .filter(p => filterLive(p) && filterDate(p.createdDate))
                  .map(item => item.batteryLevel * 10),
                type: "scatter",
                line: { color: "#dd9017", width: 1 }
              }
            ]}
            layout={{
              width: 1000,
              height: 350,
              margin: { l: 50, r: 50, t: 50, b: 50 },
              legend: { orientation: "h", xanchor: "center", x: 0.5 }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(SensorReportPage);
