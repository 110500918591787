
import React, { useState, useEffect } from "react";
import { PrincipalsService } from "../../../../services";
import FormActionsRow from "../../../../components/shared/form-actions-row";
import FormGroup from "../../../../components/shared/form-group";
import { withRouter } from "react-router-dom";



const PrincipalEditorMainSetting = props => {
    const principalid = props.match.params.id;
    const isNewPrincipal = principalid === undefined || principalid.length !== 36;

    const _principalsService = new PrincipalsService();


    const [name, setName] = useState("");
    const [key, setKey] = useState("");
  
    const [message, setMessage] = useState("");
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!isNewPrincipal) {
            _principalsService.getItem("/MainSetting/" + principalid).then(result => {
              result = result.result;
              setName(result.name);
              setKey(result.key);
              setLoaded(true);
            });
          } else {
            setLoaded(true);
          }
    }, []);

    const handleChanges = () => {
        if (!isNewPrincipal) {
          setMessage("Changes Unsaved");
        }
      };

      const submit = e => {
        setIsFormSubmitting(true);
        e.preventDefault();
    
        if (isFormSubmitting) {
          return;
        }
    
        if (isNewPrincipal) {
          _principalsService
            .createItem({
              name: name,
              key: key
            })
            .then(result => {
              if (result.isSuccess == true) {
                setMessage("Saved");
              } else {
                setMessage("Error");
              }
            });
        } else {
          _principalsService
            .updateItem({
              id: principalid,
              name: name,
              key: key
            })
            .then(result => {
              if (result.isSuccess == true) {
                setMessage("Main Settings were updated");
                setIsFormSubmitting(false);
              } else {
                setMessage("Error");
                setIsFormSubmitting(false);
              }
            });
        }    
      };

      return(  

      <form onSubmit={submit} className="pt-2">
      <div className="row">
        <div className="col-12 col-md-4 mb-5">
          <FormGroup label="principalEditor.name">
            <input
              type="text"
              autoComplete="off"
              className="form-control"
              name="name"
              value={name || ""}
              onChange={e => {
                setName(e.target.value);
                handleChanges();
              }}
            />
          </FormGroup>

          <FormGroup label="principalEditor.key">
            <input
              type="text"
              autoComplete="off"
              className="form-control"
              name="key"
              value={key || ""}
              onChange={e => {
                setKey(e.target.value);
                handleChanges();
              }}
            />
          </FormGroup>
          <h5>{message}</h5>
          <FormActionsRow
            onFormSubmit={submit}
            isFormSubmitting={isFormSubmitting}
            goBackUrl="/configuration/principals/"
          ></FormActionsRow>
        </div>
      </div>
    </form>
    ) 

};

export default withRouter(PrincipalEditorMainSetting);