import { authenticationService } from '../services/authenticationService';


function authHeader() { 
    const currentUser = authenticationService.currentUserValue; 
    if (currentUser && currentUser.token) {
        return { Authorization: `Bearer ${currentUser.token}` };
    } else {
        return {};
    }
}

async function _fetch(url, requestOptions) {
    const requestResult = await fetch(url, requestOptions);
  
    if (requestResult.status === 401){
       
        authenticationService.logout();
        window.top.location.href = "/";
        console.log('autologout')
    }

    return requestResult;
}
 
export const getJsonData = async (url) => {  
    const requestOptions = { method: 'GET', headers: authHeader() }; 
    const requestResult = await _fetch(url, requestOptions);

    const requestData = await requestResult.json();
    return requestData;
}
 
export const sendJsonData = async (url, payload, method = 'POST') => { 
    const requestOptions = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(payload) 
    };

    const requestResult =  await _fetch(url, requestOptions);
  
    const requestData = await requestResult.json();
    return requestData;
} 

export const downloadFile = async (url, method = 'GET') => {  
    const requestOptions = { method, headers: authHeader() }; 
    const requestResult = await _fetch(url, requestOptions);
     
    let fileName = '';
    const fileNameHeader = requestResult.headers.get('content-disposition');
    if (fileNameHeader){ 
        const idRegExp = /filename=([^\>]*\.[a-z]*);/;
        fileName = fileNameHeader.match(idRegExp)[1];  
    }
 
    const fileBlob = await requestResult.blob();
    return { fileBlob, fileName };
}

export const uploadFile = async (url, formData, method = 'POST') => {  
    const requestOptions = {
        method: method,
        headers: {  
            'Accept': 'application/json',
            ...authHeader()
        },
        body: formData
    };
 
    const requestResult =  await _fetch(url, requestOptions);
    const requestData = await requestResult.json();

    return requestData;
} 