import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  ListSensorService,
} from "../../services";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { LinkContainer } from "react-router-bootstrap";
import Translater from "../../components/shared/translater"
//new import
import AccessBoundary from "../../components/shared/access-boundary/index";
//new import

const SensorSettingPage = (props) => {
  const _listSensorService = new ListSensorService();

  const [sensorList, setSensorList] = useState([]);

  useEffect(() => {
    _listSensorService.getItems().then(result => {
      setSensorList(result.result);
    });
  }, []);

  return (
      <div className="mt-3 container ">
        <h2><Translater text="page.sensorshow.title"/></h2>
        <Table striped bordered hover>
          <thead className="thead-dark">
                  <tr>
                      <AccessBoundary
                          roles={["GLOBAL_ADMIN"]}
                          content={() => {
                              return (
                                  <>
                                      <th><Translater text="page.sensorsetting.sensorname" /></th>
                                  </>
                              );
                          }}
                      />
              <th><Translater text="page.sensorsetting.alias"/></th>
              <th><Translater text="page.sensorsetting.groupname"/></th>
                <th><Translater text="page.sensorsetting.action"/></th>
                                    
            </tr>
          </thead>
          <tbody>
            {sensorList.map(p => {
              return (
                  <tr>
                      <AccessBoundary
                          roles={["GLOBAL_ADMIN"]}
                          content={() => {
                              return (
                                  <>
                      <td>{p.sensorIdentifier}</td>

                      </>
                        );
                    }}
                />
                  <td>{p.alias}</td>
                <td>{p.groupName == null ? <Translater text="page.sensorsetting.nogroup" /> : p.groupName}</td>
                <td>
                                <ButtonToolbar className="ml-2">
                                <LinkContainer to={"/sensor/settings/show/" + p.id}>
                                    <Button className="ml-2" variant="success"><Translater text="shared.show"/></Button>
                                  </LinkContainer>
                                <AccessBoundary
                                    roles={["GLOBAL_ADMIN"]}
                                    content={() => {
                                        return (
                                            <>
                                        <LinkContainer to={"/sensor/settings/edit/" + p.id}>
                                            <Button className="ml-5" variant="primary"><Translater text="shared.edit" /></Button>
                                        </LinkContainer>
                                            </>
                                        );
                                    }}
                                />

                                  <LinkContainer to={"/sensor/stat/" + p.id}>
                                    <Button className="ml-5" variant="primary"><Translater text="shared.showstat"/></Button>
                                  </LinkContainer>
                                </ButtonToolbar>             
                </td>

             </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
  );
};

export default withRouter(SensorSettingPage);
