import React, { useState, useEffect } from "react";
import FormActionsRow from "../../components/shared/form-actions-row";
import FormGroup from "../../components/shared/form-group";
import Header from "../../components/shared/header";
import { UsersService, AccountPasswordResetService } from "../../services";
import { authenticationService } from '../../services/authenticationService';
  

const AccountPage = props => {
  const userid = props.location.pathname.substring(
    props.location.pathname.lastIndexOf("/") + 1
  );

  const _accountPasswordResetService = new AccountPasswordResetService();
  
  console.log(authenticationService.currentUserValue.username);

  const [username, setUsername] = useState(authenticationService.currentUserValue.username);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [message, setMessage] = useState("");


  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const _usersService = new UsersService();



  useEffect(() => {
    _usersService.getItem("/" + userid).then(result => {
      result = result.result;
      setUsername(result.userName);
      setLoaded(true);
    });
  }, []);


  const submit = e => {
    e.preventDefault();

    if (isFormSubmitting) {
      return;
    }
;
    _accountPasswordResetService
      .createItem({
        NewPassword1: password1,
        NewPassword2: password2
      })
      .then(result => {
        console.log("Return");
        console.log(result);
        if (result === "200") {
          setMessage("Password was changed");
        }
      });
    setIsFormSubmitting(true);

    setIsFormSubmitting(false);
  };

  return (
    <>
      {loaded ? (
        <div className="user-editor justify-content-center container">
<h1>Change Password</h1>
          <form onSubmit={submit}>
            <div className="row">
              <div className="col-12 col-md-4 mb-5">
                <FormGroup label="userEditor.password1">
                  <input
                    type="password"
                    autoComplete="off"
                    className="form-control"
                    name="password"
                    value={password1 || ""}
                    onChange={e => {
                      setPassword1(e.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup label="userEditor.password2">
                  <input
                    type="password"
                    autoComplete="off"
                    className="form-control"
                    name="password"
                    value={password2 || ""}
                    onChange={e => {
                      setPassword2(e.target.value);
                    }}
                  />
                </FormGroup>
                <h5>{message}</h5>
                <FormActionsRow
                  onFormSubmit={submit}
                  isFormSubmitting={isFormSubmitting}
               
                ></FormActionsRow>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </>
  );
};

export default AccountPage;
