import React, { useState, useEffect } from 'react'; 
import { withRouter, Redirect } from 'react-router-dom';   

import { authenticationService } from '../../services/authenticationService';
  


const LogoutPage = () => {    
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [message, setMessage] = useState(null); 

    const logut = authenticationService.logout();
   
    const onFormSubmit = async ({username, password}) => {  
        setIsFormSubmitting(true);
        const loginResult = await authenticationService.login(username, password);
 
         
        if (loginResult.isSuccess === false) {
            setIsFormSubmitting(false); 
            setMessage(loginResult.result.message);            
        } 
    }

    return(<Redirect to='/Login'/>);
}
 
export default withRouter(LogoutPage); 