import React, { useState, useEffect } from "react";
import { PrincipalsService } from "../../../../services";
import FormActionsRow from "../../../../components/shared/form-actions-row";
import FormGroup from "../../../../components/shared/form-group";
import { withRouter } from "react-router-dom";
import { FormCheck } from "react-bootstrap";

const PrincipalEditorEmailSetting = props => {
  const principalid = props.match.params.id;
  const isNewPrincipal = principalid === undefined || principalid.length !== 36;

  const _principalsService = new PrincipalsService();

  const [server, setServer] = useState("");
  const [port, setPort] = useState("25");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [useSSL, setUseSSL] = useState(true);
  const [enable, setEnable] = useState(false);

  const [message, setMessage] = useState("");
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!isNewPrincipal) {
      _principalsService
        .getItem("/EmailSetting/" + principalid)
        .then(result => {
          result = result.result;
          setServer(result.server);
          setPort(result.port);
          setUsername(result.username);
          setPassword(result.password);
          setUseSSL(result.useSSL);
          setEnable(result.active);

          setLoaded(true);
        });
    } else {
      return <div>First create save Principal</div>;
    }
  }, []);

  const handleChanges = () => {
    if (!isNewPrincipal) {
      setMessage("Changes Unsaved");
    }
  };

  const handleSwitchEnable = e => {
    console.log(e);
    setEnable(!enable);
  };

  const handleSwitchSSL = e => {
    setUseSSL(!useSSL);
  };

  const submit = e => {
    setIsFormSubmitting(true);
    e.preventDefault();

    if (isFormSubmitting) {
      return;
    }

    _principalsService
      .updateItemWithSubPath("EmailSetting", {
        id: principalid,
        server: server,
        port: parseInt(port),
        username: username,
        password: password,
        useSSL: useSSL,
        active: enable
      })
      .then(result => {
        if (result.isSuccess == true) {
          setMessage("Email Provider Settings were updated");
          setIsFormSubmitting(false);
        } else {
          setMessage("Error");
          setIsFormSubmitting(false);
        }
      });
  };

  return (
    <form onSubmit={submit} className="pt-2">
      <div className="row">
        <div className="col-12 col-md-4 mb-5">
          <FormGroup label="principalEditor.server">
            <input
              type="text"
              autoComplete="off"
              className="form-control"
              name="server"
              value={server || ""}
              onChange={e => {
                setServer(e.target.value);
                handleChanges();
              }}
            />
          </FormGroup>
          <FormGroup label="principalEditor.port">
            <input
              type="text"
              autoComplete="off"
              className="form-control"
              name="port"
              value={port || ""}
              onChange={e => {
                setPort(e.target.value);
                handleChanges();
              }}
            />
          </FormGroup>
          <FormGroup label="principalEditor.username">
            <input
              type="text"
              autoComplete="off"
              className="form-control"
              name="username"
              value={username || ""}
              onChange={e => {
                setUsername(e.target.value);
                handleChanges();
              }}
            />
          </FormGroup>

          <FormGroup label="principalEditor.password">
            <input
              type="password"
              autoComplete="off"
              className="form-control"
              name="key"
              value={password || ""}
              onChange={e => {
                setPassword(e.target.value);
                handleChanges();
              }}
            />
          </FormGroup>

          <FormCheck
            className="mb-2"
            id="switchSSL"
            type="switch"
            checked={useSSL}
            label="Use SSL"
            onClick={e => {
              handleSwitchSSL();
              handleChanges();
            }}
          />

          <FormCheck
            className="mb-2"
            id="switchEnable"
            type="switch"
            checked={enable}
            label="Enable"
            onClick={e => {
              handleSwitchEnable();
              handleChanges();
            }}
          />

          <h5>{message}</h5>
          <FormActionsRow
            onFormSubmit={submit}
            isFormSubmitting={isFormSubmitting}
            goBackUrl="/configuration/principals/"
          ></FormActionsRow>
        </div>
      </div>
    </form>
  );
};

export default withRouter(PrincipalEditorEmailSetting);
