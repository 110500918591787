import React, { useState, useEffect } from "react";
import FormActionsRow from "../../../components/shared/form-actions-row";
import FormGroup from "../../../components/shared/form-group";
import { withRouter, Link } from "react-router-dom";
import Select from "react-select";
import translate from "../../../core/translate";
import { GetGroupOptionsService, SensorService } from "../../../services";
import Translater from "../../../components/shared/translater";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";


const SensorSettingEditor = props => {
  const principalid = props.match.params.id;

  const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

  const [group, setGroup] = useState(EMPTY_GUID);
  const [sensorName, setSensorName] = useState("");
  const [alias, setAlias] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [co2Threshold, setCo2Threshold] = useState();
  const [dustThreshold, setDustThreshold] = useState();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [groupOptions, setGroupOptions] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const _getGroupOptionsService = new GetGroupOptionsService();
  const _sensorService = new SensorService();

  useEffect(() => {
    _getGroupOptionsService.getItems().then(result => {
      result = result.result;
      result.push({ label: "-", value: null });
      setGroupOptions(result);

      _sensorService.getItem("/" + props.match.params.id).then(result => {
        result = result.result;
        setSensorName(result.identifier);
        if (result != null) {
          setGroup(result.securityGroupId);
          setAlias(result.alias);
          if (result.latitude != 0) {
            setLatitude(result.latitude);
          }
          if (result.longitude != 0) {
            setLongitude(result.longitude);
          }
          setCo2Threshold(result.co2Threshold)
          setDustThreshold(result.dustThreshold)
        }
        setLoaded(true);
      });
    });
  }, []);

  const handleChanges = e => {
    setGroup(e.value);
    setMessage("Changes Unsaved");
  };

  const submit = e => {
    e.preventDefault();

    if (isFormSubmitting) {
      return;
    }
    setIsFormSubmitting(true);
    var result = {
      id: props.match.params.id,
      groupId: group,
      alias: alias == "" ? null : alias,
      latitude: latitude == 0 || latitude == "" ? 0 : latitude,
      longitude: longitude == 0 || longitude == "" ? 0 : longitude,
      co2Threshold: co2Threshold,
      dustThreshold: dustThreshold
    };

    _sensorService.updateItem(result).then(result => {
      if (result.result) {
        setMessage("saved");
      } else {
        setMessage(JSON.stringify(result))
      }
    });

    setIsFormSubmitting(false);
  };

  return (
    <>
      {loaded ? (
        <div className="user-editor justify-content-center container">
          <h3>
            <Translater text="page.sensorEditor.title" /> {sensorName} <LinkContainer to={"/map/"}><span className="fas fa-map-marked-alt"></span>
</LinkContainer>

          </h3>

          <form onSubmit={submit}>
            <div className="row">
              <div className="col-12 col-md-4 mb-5">
                <FormGroup label="sensorEditor.alias">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    name="alias"
                    value={alias || ""}
                    onChange={e => {
                      setAlias(e.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup label="sensorEditor.latitude">
                  {" "}
                  eg. 50.12345
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    name="alias"
                    value={latitude || ""}
                    onChange={e => {
                      setLatitude(e.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup label="sensorEditor.longitude">
                  {" "}
                  eg. -20.12345
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    name="alias"
                    value={longitude || ""}
                    onChange={e => {
                      setLongitude(e.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup label="sensorEditor.Group">
                  <Select
                    closeMenuOnSelect={true}
                    options={groupOptions}
                    onChange={handleChanges}
                    defaultValue={groupOptions.filter(
                      val => val.value == group
                    )}
                  />
                </FormGroup>
                
                <FormGroup label="sensorEditor.co2Threshold">
                  {" "}
                  (ppm)
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    name="alias"
                    value={co2Threshold || ""}
                    onChange={e => {
                        setCo2Threshold(e.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup label="sensorEditor.dustThreshold">
                  {" "}
                  (&micro;g/m&sup3;)
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    name="alias"
                    value={dustThreshold || ""}
                    onChange={e => {
                      setDustThreshold(e.target.value);
                    }}
                  />
                </FormGroup>
                <h5>{message}</h5>
                <FormActionsRow
                  onFormSubmit={submit}
                  isFormSubmitting={isFormSubmitting}
                  goBackUrl="/sensor/settings/"
                ></FormActionsRow>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </>
  );
};

export default withRouter(SensorSettingEditor);
