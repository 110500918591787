import React from 'react';

const SelectControl = ({ name, value, className, options = [], onChange }) => {
    return (
        <select  
            className={className || 'form-control'} 
            name={name}
            value={value || ''}
            onChange={(e) => onChange(e.target.value)} >
            <option value=""></option>
            {
                options && options.map(option => {  
                    return <option key={option.key} value={option.key}>{`${option.value}`}</option>
                })
            }
        </select>
    );
}

export default SelectControl;